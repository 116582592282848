import React, { Component,PureComponent,useState,useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { web3 } from './App';
const mainnet = '1'

class VerifyC extends Component{
  componentDidUpdate(){
    const { discordUser,account } = this.props;
    if(discordUser && account && account !== ''){
      this.requestVerify();
    }
  }

  requestVerify = () => {
    const { account,discordUser,SetIsFail } = this.props;
    const msgParams = {
      domain: {
        chainId: '1',
        name: 'Ether Mail',
        verifyingContract: '0xCcCCccccCCCCcCCCCCCcCcCccCcCCCcCcccccccC',
        version: '1',
      },
      message: {
        contents: 'OrangeHare PFP airdrop event',
        from: {
          name: 'OrangeHare Holder',
          wallets: [
            '0xc3D909683270F33842DD8F566f8F960cA219875D',
          ],
        },
        to: [
          {
            name: 'OrangeHare Project',
            wallets: [
              '0xc3D909683270F33842DD8F566f8F960cA219875D',
            ],
          },
        ],
        attachment: '0x',
      },
      primaryType: 'Mail',
      types: {
        EIP712Domain: [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
          { name: 'verifyingContract', type: 'address' },
        ],
        Group: [
          { name: 'name', type: 'string' },
          { name: 'members', type: 'Person[]' },
        ],
        Mail: [
          { name: 'from', type: 'Person' },
          { name: 'to', type: 'Person[]' },
          { name: 'contents', type: 'string' },
          { name: 'attachment', type: 'bytes' },
        ],
        Person: [
          { name: 'name', type: 'string' },
          { name: 'wallets', type: 'address[]' },
        ],
      },
    };
    var params = [account, msgParams];
    var method = 'eth_signTypedData_v4';
    web3.currentProvider.sendAsync(
      {
        method,
        params,
        account,
      },
      function (err, result) {
        if (err) return console.dir(err);
        if (result.error) {
          alert(result.error.message);
        }
        if (result.error) return console.error('ERROR', result);
        const resultSig = JSON.stringify(result.result);
        axios.post("https://ohapi.justlabs.io/verify",{sig:resultSig,discordId:discordUser,account}).then((res)=>{
          if (res.status === 200){
            window.location.replace("https://discord.com/channels/1062603620887044166");
          }else{
            SetIsFail(true);
          }
        }).catch((error)=>{
          SetIsFail(true);
        })
      }
  )
  }

  render(){
    const {isFail} = this.props;
    return(
      <div style={{alignSelf:'center',justifyContent:'center',textAlign:'center'}}>
        {isFail ? <h1 style={{marginTop:100,alignSelf:'center'}}>You are not an OrangeHare holder.<br/>Please sign with the wallet that holds the OrangeHare NFT.</h1> :
  <h1 style={{marginTop:100,alignSelf:'center'}}>Authenticating OrangeHare holder.<br/><br/>After signing the MetaMask, check the newly granted PFP Member role in Discord</h1>}
      </div>
    )
  }
}


export default function Verify(props) {
  const location = useLocation();
  const [discordUser, setDiscordUser] = useState();
  const [account,SetAccount] = useState('');
  const [isLoading,SetIsLoading] = useState(true);
  const [isMainNet,SetIsMainNet] = useState(window.ethereum.networkVersion === mainnet);
  const [isFail,SetIsFail] = useState(false);
  
  useEffect(()=>{
    if(window.ethereum){
      window.ethereum.request({method:'eth_requestAccounts'})
        .then(res=>{
          SetIsMainNet(window.ethereum.networkVersion === mainnet);
          SetAccount(res[0]);
          SetIsLoading(false);
        })
    }else{
      alert("install metamask extension!!")
    }
    window.ethereum.on('accountsChanged', function (accounts) {
      SetAccount(accounts[0]);
    })
    
    window.ethereum.on('networkChanged', function (networkId) {
      console.log(networkId === mainnet)
      if (networkId === mainnet){
        SetIsMainNet(true);
      }else{
        SetIsMainNet(false);
      }
    })
  }
  ,[])


  const queryString = location.hash === '' ? null : location.hash.split("&");
  const tokenType = queryString ? queryString[0].replace("#token_type=","") : null;
  const accessToken = queryString ? queryString[1].replace("access_token=","") : null;
  useEffect(() => {
    const fetchUsers = () => {
        fetch('https://discord.com/api/users/@me', {
          headers: {
            authorization: `${tokenType} ${accessToken}`,
          },
        })
        .then(result => result.json())
        .then(response => {
          // response format 
          /*
          {
                "id": "<user_id>",
                "username": "Poopeye",
                "avatar": "3118e64af30fc703b9bf3328d156155c",
                ...
            }
          */
          // user as avatar URL: `https://cdn.discordapp.com/avatars/${discordUser.id}/${discordUser.avatar}.png`
          setDiscordUser(response.id);
        })
        .catch(console.error);
    };

    if (accessToken) {
      fetchUsers();
    }
  }, []);
  return (
    <VerifyC
      {...props}
      SetIsFail={SetIsFail}
      isFail={isFail}
      isMainNet={isMainNet}
      isLoading={isLoading}
      account={account}
      discordUser={discordUser}
    />
  );
}